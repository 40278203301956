import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import HomepageTemplate from 'gatsby-theme-carbon/src/templates/Homepage';
export const _frontmatter = {
  "title": "Homepage"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const FeatureCard = makeShortcode("FeatureCard");
const ArtDirection = makeShortcode("ArtDirection");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const ResourceCard = makeShortcode("ResourceCard");
const MdxIcon = makeShortcode("MdxIcon");
const ArticleCard = makeShortcode("ArticleCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = HomepageTemplate;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <FeatureCard color="dark" href="/get-started/design" subTitle="Start" title="Designing" actionIcon="arrowRight" className="homepage-feature" mdxType="FeatureCard">
      <ArtDirection mdxType="ArtDirection">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "50%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsTAAALEwEAmpwYAAAApElEQVQoz+XSMQ4BURSF4SeZp1AqTGUJNiBKldoKZgMapQWwAp3SFpQa1qCYXqmhMBHnd6abCAmRqdwFfPmTe0IIQY1mqtgeKkkzJd2ZYm+t2N8qjnK1srM6UzSYS+MlmqzRYoNWe7TL0eGIThd0vVHe3V7AIAYxSAXEIAYxiEEMYhCDGMQgBinBogJWC5/BjwuLF4XvwK8L/xCs7Sm1zObnYT8ACmQHKavKNk4AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Get started for designers",
            "title": "Get started for designers",
            "src": "/static/714db3c141e2e35bc62de90825ac2e72/fb070/getting-started-designers-mobile.png",
            "srcSet": ["/static/714db3c141e2e35bc62de90825ac2e72/d6747/getting-started-designers-mobile.png 288w", "/static/714db3c141e2e35bc62de90825ac2e72/09548/getting-started-designers-mobile.png 576w", "/static/714db3c141e2e35bc62de90825ac2e72/fb070/getting-started-designers-mobile.png 1152w", "/static/714db3c141e2e35bc62de90825ac2e72/dee76/getting-started-designers-mobile.png 1600w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "50%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsTAAALEwEAmpwYAAAApElEQVQoz+XSMQ4BURSF4SeZp1AqTGUJNiBKldoKZgMapQWwAp3SFpQa1qCYXqmhMBHnd6abCAmRqdwFfPmTe0IIQY1mqtgeKkkzJd2ZYm+t2N8qjnK1srM6UzSYS+MlmqzRYoNWe7TL0eGIThd0vVHe3V7AIAYxSAXEIAYxiEEMYhCDGMQgBinBogJWC5/BjwuLF4XvwK8L/xCs7Sm1zObnYT8ACmQHKavKNk4AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Get started for designers",
            "title": "Get started for designers",
            "src": "/static/0662e09c0b7fcc0ee0e7b8e912f8aa7c/fb070/getting-started-designers-tablet.png",
            "srcSet": ["/static/0662e09c0b7fcc0ee0e7b8e912f8aa7c/d6747/getting-started-designers-tablet.png 288w", "/static/0662e09c0b7fcc0ee0e7b8e912f8aa7c/09548/getting-started-designers-tablet.png 576w", "/static/0662e09c0b7fcc0ee0e7b8e912f8aa7c/fb070/getting-started-designers-tablet.png 1152w", "/static/0662e09c0b7fcc0ee0e7b8e912f8aa7c/dee76/getting-started-designers-tablet.png 1600w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "33.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAYAAAAIy204AAAACXBIWXMAAAsSAAALEgHS3X78AAAAd0lEQVQoz2NgZBH6z8Lv+J9ZOPY/k1jlfwaluf8ZdLb9Z7C/9p/B99l/hoz//2Ur//236fz3P3r2//+Fy///79n1///C4///H7/z//+N5///v/vy//+P3//BgIEBaiCTUOx/RtEK7AZWkGAg3IXUMpBh1EBKDQQA2ftyUuvMYaUAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Get started for designers",
            "title": "Get started for designers",
            "src": "/static/82ad8166ed316ef73e8dd27b60b552cf/fb070/getting-started-designers.png",
            "srcSet": ["/static/82ad8166ed316ef73e8dd27b60b552cf/d6747/getting-started-designers.png 288w", "/static/82ad8166ed316ef73e8dd27b60b552cf/09548/getting-started-designers.png 576w", "/static/82ad8166ed316ef73e8dd27b60b552cf/fb070/getting-started-designers.png 1152w", "/static/82ad8166ed316ef73e8dd27b60b552cf/fb104/getting-started-designers.png 1728w", "/static/82ad8166ed316ef73e8dd27b60b552cf/23205/getting-started-designers.png 1920w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
      </ArtDirection>
    </FeatureCard>
    <FeatureCard color="dark" href="/get-started/develop/vanilla" subTitle="Start" title="Developing" actionIcon="arrowRight" className="homepage-feature" mdxType="FeatureCard">
      <ArtDirection mdxType="ArtDirection">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "50%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAjklEQVQoz2NgAAFGBgYmBooAIxMDIyPp2vhUGJTCGZhYybKThZNBp4jBaiqDsAHMFyQ4GKyaW4bBYxeDjDtYhJlYrSCCX53BoIZBt5iBQ5REy/mUGcz7GcSsSHczEDBzMjBzIFzLxsfAq8jAI8fAq8TAJkCEiZB4YgTHtoQ9g+VEBuNmBsvJDNKuUFmqAwAI9grPzt1wPgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Get started for developers",
            "title": "Get started for developers",
            "src": "/static/457c503d0d7c2f03242cbce24dd36cba/fb070/getting-started-developers-mobile.png",
            "srcSet": ["/static/457c503d0d7c2f03242cbce24dd36cba/d6747/getting-started-developers-mobile.png 288w", "/static/457c503d0d7c2f03242cbce24dd36cba/09548/getting-started-developers-mobile.png 576w", "/static/457c503d0d7c2f03242cbce24dd36cba/fb070/getting-started-developers-mobile.png 1152w", "/static/457c503d0d7c2f03242cbce24dd36cba/dee76/getting-started-developers-mobile.png 1600w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "50%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAZ0lEQVQoz2NgoAJgZCJPGyMDuyCQItda+UAGpXAGZg5yrAYRylEMhnVgLhNYhHiHSLsyqMYzcIqTFVTcsgxMrCA2uzCDsAEIARlwRxFhBDOI5FVkUAgGIR4FUjRTI7YZQU4AIaLsBABFmgWG42pvdgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Get started for designers",
            "title": "Get started for designers",
            "src": "/static/0410f90d413ade9c6a7b9344c7170c9b/fb070/getting-started-developers-tablet.png",
            "srcSet": ["/static/0410f90d413ade9c6a7b9344c7170c9b/d6747/getting-started-developers-tablet.png 288w", "/static/0410f90d413ade9c6a7b9344c7170c9b/09548/getting-started-developers-tablet.png 576w", "/static/0410f90d413ade9c6a7b9344c7170c9b/fb070/getting-started-developers-tablet.png 1152w", "/static/0410f90d413ade9c6a7b9344c7170c9b/dee76/getting-started-developers-tablet.png 1600w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "33.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAYAAAAIy204AAAACXBIWXMAAAsSAAALEgHS3X78AAAAYElEQVQoz2NgYGD4D8GM/xFsSjCn5H8GRhYqGQbCQgb/GVRi/zPwyFPLUCChDDRQLRkiwMhEoffFbf6DMdW8jMwRNgK6NOU/g1wA0KXMlBjIBOEwsf9nYOX/z8DCQ7YLAQtzj64Q5cVEAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Get started for developers",
            "title": "Get started for developers",
            "src": "/static/1b7ac2e6f4a7eeeae43769a4a6dfb694/fb070/getting-started-developers.png",
            "srcSet": ["/static/1b7ac2e6f4a7eeeae43769a4a6dfb694/d6747/getting-started-developers.png 288w", "/static/1b7ac2e6f4a7eeeae43769a4a6dfb694/09548/getting-started-developers.png 576w", "/static/1b7ac2e6f4a7eeeae43769a4a6dfb694/fb070/getting-started-developers.png 1152w", "/static/1b7ac2e6f4a7eeeae43769a4a6dfb694/fb104/getting-started-developers.png 1728w", "/static/1b7ac2e6f4a7eeeae43769a4a6dfb694/23205/getting-started-developers.png 1920w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
      </ArtDirection>
    </FeatureCard>
    <h3 {...{
      "id": "other-resources"
    }}>{`Other resources`}</h3>
    <p>{`The component libraries give developers a collection of reusable components for
building websites and user interfaces. See a
`}<a parentName="p" {...{
        "href": "/resources"
      }}>{`complete list of resources.`}</a></p>
    <Row className="resource-card-group" mdxType="Row">
      <Column colLg={4} colMd={4} noGutterSm mdxType="Column">
  <ResourceCard color="dark" subTitle="Sketch libraries" href="/resources#theme-libraries" mdxType="ResourceCard">
      <MdxIcon name="sketch" mdxType="MdxIcon" />
  </ResourceCard>
      </Column>
      <Column colLg={4} colMd={4} noGutterSm mdxType="Column">
  <ResourceCard color="dark" subTitle="Carbon Components" href="https://github.com/carbon-design-system/carbon/tree/master/packages/components" mdxType="ResourceCard">
          <MdxIcon name="github" color="inverse" mdxType="MdxIcon" />
  </ResourceCard>
      </Column>
      <Column colLg={4} colMd={4} noGutterSm mdxType="Column">
  <ResourceCard color="dark" subTitle="Carbon Components React" href="https://github.com/carbon-design-system/carbon/tree/master/packages/react" mdxType="ResourceCard">
          <MdxIcon name="github" color="inverse" mdxType="MdxIcon" />
  </ResourceCard>
      </Column>
      <Column colLg={4} colMd={4} noGutterSm mdxType="Column">
  <ResourceCard color="dark" subTitle="Carbon Components Angular" href="https://github.com/carbon-design-system/carbon-components-angular" mdxType="ResourceCard">
          <MdxIcon name="github" color="inverse" mdxType="MdxIcon" />
  </ResourceCard>
      </Column>
      <Column colLg={4} colMd={4} noGutterSm mdxType="Column">
  <ResourceCard color="dark" subTitle="Carbon Components Vue" href="https://github.com/carbon-design-system/carbon-components-vue" mdxType="ResourceCard">
          <MdxIcon name="github" color="inverse" mdxType="MdxIcon" />
  </ResourceCard>
      </Column>
    </Row>
    <h3 {...{
      "id": "latest-articles"
    }}>{`Latest articles`}</h3>
    <Row mdxType="Row">
      <Column colLg={4} colMd={4} noGutterMdLeft mdxType="Column">
        <ArticleCard color="dark" title="What’s around the IBM Carbon corner: October 🎃" author="Josefina Mancilla" date="September 6, 2019" href="https://medium.com/carbondesign/whats-around-the-carbon-corner-october-a47532f2933" mdxType="ArticleCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1152px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "56.25%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsSAAALEgHS3X78AAABIUlEQVQoz5VSu07DQBC88A4BCpoAFaKDBDuOkHABH0ESB/MQCCEMIggI4tGRmgLlCxAFHzrMXAyyEJbOxehWs3Nzu7dr9k0NWXTNhj0jnoelGi6nPcsJisVFqeZHm4X5SwhtCm/KDfTnArwvhbivBL+xTuXa/5jlGrbMOh548XzSQzLl4aA04i8Yi1NOGmdDvX5XaeB0vI6jsTpicjI6Znw2sWlzhSrsUHw94+Otuo2P1R18Eq+LW/ha28XLQhO9sm81hQx7/KcBTYbLIYYrIZ5p9DTf5GB83M4WrHDUcmAnqkm2CLW+x39T28o5G8bpUB5ZjaarQVyxfa1MQohTTprYtULt2QkHovaS1MiC5uKUi1wrzC52JweFF7ub87qL5huqa1P5DQFe0QAAAABJRU5ErkJggg==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "october release",
              "title": "october release",
              "src": "/static/409a771650bb2070570e20be8d675217/fb070/october_release.png",
              "srcSet": ["/static/409a771650bb2070570e20be8d675217/d6747/october_release.png 288w", "/static/409a771650bb2070570e20be8d675217/09548/october_release.png 576w", "/static/409a771650bb2070570e20be8d675217/fb070/october_release.png 1152w", "/static/409a771650bb2070570e20be8d675217/dee76/october_release.png 1600w"],
              "sizes": "(max-width: 1152px) 100vw, 1152px",
              "loading": "lazy"
            }}></img>{`
    `}</span>
        </ArticleCard>
      </Column>
      <Column colLg={4} colMd={4} noGutterMdLeft mdxType="Column">
        <ArticleCard color="dark" title="Carbon for IBM Security" author="Jen Downs" date="August 20, 2019" href="https://medium.com/carbondesign/carbon-for-ibm-security-986ccfe5c012" mdxType="ArticleCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1152px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "56.25990491283677%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAAsSAAALEgHS3X78AAACMUlEQVQoz03OzU/TcBzH8fb3/OvTr8/t1rVb162DDZiOQSMYERxQRU4cFBOxiw+JePGgB40s7ODFg4nRP9jOk8nr+v58v9JR8uW4szjpLB+ly4NsuZ/dFtntpL/Y6i9G+c1g8C0b3HTWFvFw2dz8Hox/uJOf5vSXvvubFX+ku/7z7eDlNKwmjfm4Od9szofRfNCqeq0qjaskqaKkCtvzIH3r9a6d/KO5/tkYfVU2b+jWQuqZh7l9nNunfafMnDJ1y7Zbxm4Z+aVrzWx75galEzy2Gudm60K0L/W0UnvveP4eDz5IkTZJjJ3EKFpGEYmiIYpAFL5ZWPp2PztJuzNdTA1rV3f2NO9AC4+V6CmPL2j7GU5fSAFfC5VRqG4E6oanjpyaPtJZPtk6PT979eb1p8HaQ8wyVYy4OebOLvPu0/CINE9R60yyaMdhWc3mmcUzwbtC6XLc2r/3xLZ6V1fXk+1DCbhMbVM1o8Y6scbE2cHeHvIfSII0BYkFXdFprNFIZRFFfhwNs+748nIeJ+uSbBDmYRZiJcFaD4shsu5AZypp2NdJWNNIqJKAE59jlyKbEcsym4GfMGYCoECkQ2wi6iEeIS2FRg7NocSRpWKnxrHDsE2RRZDASEdQkWUqywgAAgAFgAFYTxiQOJA3oJoArStRaDAkahQJggyMtDpDkEFAISC1uv8Hr1YgA0gDxAbMB7wpYaAQqNUwVPEq43B1h4BVAGUZ/AfKAK++QCrAAlDnL7TsSzaIPWDMAAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "security pic",
              "title": "security pic",
              "src": "/static/2a156a290896898147238a99263d06a6/fb070/security_pic.png",
              "srcSet": ["/static/2a156a290896898147238a99263d06a6/d6747/security_pic.png 288w", "/static/2a156a290896898147238a99263d06a6/09548/security_pic.png 576w", "/static/2a156a290896898147238a99263d06a6/fb070/security_pic.png 1152w", "/static/2a156a290896898147238a99263d06a6/9a4ba/security_pic.png 1262w"],
              "sizes": "(max-width: 1152px) 100vw, 1152px",
              "loading": "lazy"
            }}></img>{`
    `}</span>
        </ArticleCard>
      </Column>
      <Column colLg={4} colMd={4} noGutterMdLeft mdxType="Column">
        <ArticleCard color="dark" title="Carbon: July recap + August preview" author="Josefina Mancilla" date="July 26, 2019" href="https://medium.com/carbondesign/carbon-july-preview-whats-coming-in-august-7a2483ea6ac9" mdxType="ArticleCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1152px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "56.25%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsSAAALEgHS3X78AAABAElEQVQoz51SXUsCURT03/vUP5AiEOmliB4CJY1q/QoWtdL1O0xdF/oNd6Y7e1EWuYTrw3AuM3OGe+65hXhEHmM7BHcRufkA520ceJ3FSZPH11vwhk3IyQsY1cF22dZG5myrNHl8od7AxJpHT+CyCy50q0+HRcdy70i1JG/g+Bn86dsGjfeFFGs77ipEquUK3I3JWQD27sHHomGnAgaXhtULw/4DOH1znlyBagpvwdeSYXBl2L0Bm9eG4d0ZgRpHi9C7/X4zHVucFrEenDFyMiWHNbfdecth1nQLEidNnpMDdaNVzwVpswraQ5y0ODoxMBuqd/Ihzvux9/U/ZL1Z/AGiTSpzBlTieQAAAABJRU5ErkJggg==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Aug preview rev 2",
              "title": "Aug preview rev 2",
              "src": "/static/2da540366f2ae903eb55acb32be3f234/fb070/Aug_preview_rev_2.png",
              "srcSet": ["/static/2da540366f2ae903eb55acb32be3f234/d6747/Aug_preview_rev_2.png 288w", "/static/2da540366f2ae903eb55acb32be3f234/09548/Aug_preview_rev_2.png 576w", "/static/2da540366f2ae903eb55acb32be3f234/fb070/Aug_preview_rev_2.png 1152w", "/static/2da540366f2ae903eb55acb32be3f234/dee76/Aug_preview_rev_2.png 1600w"],
              "sizes": "(max-width: 1152px) 100vw, 1152px",
              "loading": "lazy"
            }}></img>{`
    `}</span>
        </ArticleCard>
      </Column>
    </Row>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      